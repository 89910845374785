<div class="flex flex-col items-center justify-start">
    <div
        [ngClass]="isHidden ? 'hidden' : 'flex'"
        class="w-screen flex-col gap-8 px-0 py-3 sm:w-[600px] sm:gap-4"
    >
        <div class="flex flex-col px-3 sm:px-0">
            <div
                class="flex h-[80px] flex-row items-center justify-start gap-4 pb-4 text-3xl font-bold sm:h-[100px]"
            >
                <span class="grow">{{ customerName }}</span>
                <img
                    [src]="logo | secure | async"
                    class="max-h-[80px] max-w-[80px] sm:max-h-[100px] sm:max-w-[100px]"
                />
            </div>
            <div class="flex flex-col text-base lg:flex-row">
                <div class="flex flex-row gap-3">
                    <span class="font-bold">{{
                        'bill.billing_period' | translate
                    }}</span>
                    <span *ngIf="isLoading; else dateRange"
                        >{{ 'loading' | translate }}
                    </span>
                    <ng-template #dateRange>
                        <span>{{
                            startDateString + ' - ' + endDateString
                        }}</span>
                    </ng-template>
                </div>
                <div class="flex-1"></div>
                <div class="flex flex-row gap-3">
                    <span class="font-bold">{{
                        'bill.total_data_usage' | translate
                    }}</span>
                    <span *ngIf="isLoading; else dataUsage"
                        >{{ 'loading' | translate }}
                    </span>
                    <ng-template #dataUsage>
                        <span>{{ totalDataUsageString }}</span>
                    </ng-template>
                </div>
            </div>
        </div>
        <div id="devices-table">
            <sz-table caption="device.plural" [options]="deviceTableOptions" />
        </div>
        <div id="bill-table">
            <sz-table
                caption="bill"
                [options]="billTableOptions"
                [striped]="false"
            />
        </div>
    </div>
</div>
