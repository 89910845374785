import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToolBarChip } from '../common/layout/tool-bar/tool-bar-chip.model';
import { ToolBarPageMixin } from '../common/layout/tool-bar/tool-bar-page.mixin';
import { DateRangeDialog } from '../dialogs/date-range-dialog/date-range-dialog.component';
import { SelectUserDialog } from '../dialogs/select-user-dialog/select-user-dialog.component';
import { RouterIcon } from '../root/model/root.model';
import { ApiService } from '../services/api/api.service';
import { ensureLoaded } from '../services/api/cached-api';
import { CustomerRole } from '../services/constants';
import { DataTableLanguageService } from '../services/data-table-language/data-table-language.service';
import { DateTimeService } from '../services/date-time.service';
import { DialogService } from '../services/dialog/dialog.service';
import { SettingsService } from '../services/user/settings.service';
import { hasRole } from '../shared/common';
import { GroupByType } from '../shared/models/page-view';

@Component({
    selector: 'app-page-views',
    templateUrl: './page-views.component.html',
})
export class PageViewsComponent extends ToolBarPageMixin implements OnInit {
    startDate: Date = new Date();
    endDate: Date = new Date();
    sections = [
        {
            id: 'date',
            title: 'utilities.dates',
            icon: 'calendar-range',
        },
        {
            id: 'hour',
            title: 'utilities.hours',
            icon: 'clock-outline',
        },
        {
            id: 'feature',
            title: 'utilities.features',
            icon: 'shape-outline',
        },
        {
            id: 'user',
            title: 'user.plural',
            icon: RouterIcon.USERS,
        },
    ];
    selectedIndex = 0;
    tableOptions;
    individualTableOptions;
    userId: number = null;

    get selectedSectionId() {
        return this.sections[this.selectedIndex].id;
    }

    get selectedSectionColumnName(): string {
        switch (this.selectedSectionId) {
            case 'date':
                return 'utilities.date';
            case 'hour':
                return this.dateTimeService.isUtcTime
                    ? 'utilities.hour.utc'
                    : 'utilities.hour';
            case 'feature':
                return 'utilities.feature';
            case 'user':
                return 'user';
        }
    }

    get dateRangeString() {
        const toString = (d) => this.dateTimeService.dateString(d);
        const startDateString = toString(this.startDate);
        let endDateString = '';
        if (this.endDate) {
            endDateString = toString(this.endDate);
        }
        if (this.startDate == this.endDate) {
            return startDateString;
        }

        return startDateString + ' - ' + endDateString;
    }

    get isSingleDayDateRange() {
        // Resetting the time part to ensure only the date part is compared
        const start = new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth(),
            this.startDate.getDate(),
        ).toISOString();
        const end = new Date(
            this.endDate.getFullYear(),
            this.endDate.getMonth(),
            this.endDate.getDate(),
        ).toISOString();
        return start === end;
    }

    constructor(
        private dataTableLanguageService: DataTableLanguageService,
        private api: ApiService,
        private translate: TranslateService,
        private dateTimeService: DateTimeService,
        private dialog: DialogService,
        private settings: SettingsService,
    ) {
        super();
    }

    async ngOnInit() {
        if (hasRole(CustomerRole.Super)) {
            await ensureLoaded([this.api.users]);
            this.sections.push({
                id: 'all',
                title: 'common.all',
                icon: 'notification-clear-all',
            });
        }
        const aYearAgo = new Date();
        aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
        this.startDate = aYearAgo;
        this.endDate = new Date();
        // Let the date format setting load.
        this.settings.loaded$.subscribe(() => {
            this.setupToolBar();
            this.loadTable();
        });
    }

    setupToolBar() {
        this.clearToolBar();
        this.toolBarChips = [
            new ToolBarChip({
                id: 'dateRange',
                icon: 'calendar-range',
                text: this.dateRangeString,
                callback: () => this.selectDate(),
            }),
            new ToolBarChip({
                id: 'user',
                icon: RouterIcon.USERS,
                text: 'user.all',
                callback: () => this.selectUser(),
            }),
        ];
    }

    async selectUser() {
        const user = await this.dialog.open(SelectUserDialog, {
            width: 'auto',
            data: {
                title: 'customer.user.select',
                description: 'page_views.select_user.description',
                submitLabel: 'btn.confirm',
            },
        });
        if (user) {
            this.userId = user.id;
            this.updateToolBar('user', 'text', user.contactFullName);
        } else {
            this.userId = null;
            this.updateToolBar('user', 'text', 'user.all');
        }
        this.loadTable();
    }

    async selectDate() {
        const dateResponse = await this.dialog.open(DateRangeDialog, {
            width: 'auto',
            data: {
                title: 'btn.select_date_range',
                startDate: this.startDate,
                endDate: this.endDate,
                includeTime: false,
            },
        });
        if (dateResponse) {
            this.startDate = dateResponse.startDate;
            if (dateResponse.end) {
                this.endDate = dateResponse.endDate;
            } else {
                this.endDate = new Date();
            }
            this.updateToolBar('dateRange', 'text', this.dateRangeString);
            this.loadTable();
        }
    }
    async loadTable() {
        const defaultOrder = this.getDefaultOrder();
        const commonOptions = {
            destroy: true,
            responsive: { details: false },
            columns: this.getColumnsConfig(),
            order: defaultOrder,
            ajax: this.getAjaxConfig(),
            deferRender: true,
            info: false,
            searching: false,
            paging: false,
            language: this.dataTableLanguageService.getLanguageData(),
        };
        this.tableOptions = this.individualTableOptions = commonOptions;
    }

    getDefaultOrder() {
        switch (this.selectedSectionId) {
            case 'date':
                return [[0, 'desc']];
            case 'hour':
                return [[0, 'asc']];
            case 'all':
                return [[4, 'desc']];
            default:
                return [[1, 'desc']];
        }
    }

    getColumnsConfig() {
        if (this.selectedSectionId === 'all') {
            return [
                {
                    data: 'path',
                    title: this.translate.instant('utilities.path'),
                    width: '20%',
                },
                {
                    data: 'feature',
                    title: this.translate.instant('utilities.feature'),
                    width: '20%',
                },
                {
                    data: 'userId',
                    title: this.translate.instant('user'),
                    render: (data, type) => {
                        if (type == 'display') {
                            if (data) {
                                return this.api.users.get(+data)
                                    .contactFullName;
                            } else {
                                return this.translate.instant('user.unknown');
                            }
                        }
                        return data;
                    },
                    width: '20%',
                },
                {
                    data: 'screenWidth',
                    title: this.translate.instant('page_views.width'),
                    width: '20%',
                },
                {
                    data: 'timestamp',
                    title: this.translate.instant('page_views.timestamp'),
                    render: (data, type) => {
                        if (type == 'display') {
                            return this.dateTimeService.timeString(data);
                        }
                        return data;
                    },
                    width: '20%',
                },
            ];
        }

        // Default columns
        return [
            {
                data: 'displayValue',
                title: this.translate.instant(this.selectedSectionColumnName),
                render: (data, type) => {
                    if (type == 'display') {
                        if (
                            this.selectedSectionId == 'date' &&
                            typeof data == 'string'
                        ) {
                            const [year, month, day] = data.split('-');
                            const parsedYear = Number(year);
                            const parsedMonth = Number(month);
                            const parsedDay = Number(day);
                            if (
                                !isNaN(parsedYear) &&
                                !isNaN(parsedMonth) &&
                                !isNaN(parsedDay)
                            ) {
                                const date = new Date(
                                    parsedYear,
                                    parsedMonth - 1,
                                    parsedDay,
                                );
                                return this.dateTimeService.dateString(date);
                            }
                        } else if (
                            this.selectedSectionId == 'user' &&
                            data == null
                        ) {
                            return this.translate.instant('user.unknown');
                        }
                        return data;
                    } else {
                        if (this.selectedSectionId == 'date') {
                            return new Date(data);
                        }
                        return data;
                    }
                },
            },
            {
                data: 'count',
                title: this.translate.instant('utilities.page_views'),
                width: '20%',
            },
        ];
    }

    getAjaxConfig() {
        if (this.selectedSectionId === 'all') {
            return async (_, callback) => {
                const requestData = {
                    'timestamp[gte]': this.startDate,
                    'timestamp[lte]': this.endDate,
                };
                if (this.userId) {
                    requestData['userId'] = this.userId;
                }
                const pageViewData =
                    await this.api.pageViews.query(requestData);
                callback({ data: pageViewData });
            };
        }

        // Default AJAX
        return async (_, callback) => {
            const timezoneOffset = this.dateTimeService.isUtcTime
                ? 0
                : new Date().getTimezoneOffset();
            const requestData = {
                groupBy: this.selectedSectionId as GroupByType,
                timezoneOffset,
                startDate: this.startDate,
                endDate: this.endDate,
            };
            if (this.userId) {
                requestData['userId'] = this.userId;
            }
            const pageViewData = await this.api.pageViews.getStats(requestData);
            callback({ data: pageViewData });
        };
    }

    setTodayDateRange() {
        const date = new Date();
        // Get start date from the beginning of the day
        this.startDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDay(),
        );
        this.endDate = date;
        this.updateToolBar('dateRange', 'text', this.dateRangeString);
    }
}
