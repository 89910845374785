import {
    Component,
    DestroyRef,
    ElementRef,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouteMetadataService } from 'src/app/services/route-metadata/route-metadata.service';
import { AppService } from '../../app.service';
import { ApiService } from '../../services/api/api.service';
import { SearchService } from '../../services/search/search.service';
import { clone, isDefined } from '../../shared/common';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
})
export class NavBarComponent implements OnInit {
    icon: string;
    title = '';
    hasUnreadAnnouncements = false;
    allowHoverMenuTriggerTimestamp = 0;
    showMenu = false;
    canSearch = false;
    showSearch = false;
    _searchValue = '';
    showSaveIcon = false;

    @ViewChild('searchInput') searchElement: ElementRef;
    destroyRef = inject(DestroyRef);

    get inputClasses(): string {
        let classes =
            'rounded-md border-0 outline-none ' +
            'text-sm text-white bg-white/20 placeholder:text-on-primary-500 ';
        if (this.showSearch) {
            classes += 'h-7 w-24 sm:w-36 my-1 mx-1 py-0 px-2 ';
        } else {
            classes += 'w-0 m-0 p-0 ';
        }
        return classes;
    }

    get searchValue() {
        return this._searchValue;
    }

    set searchValue(value) {
        if (value == this._searchValue) {
            return;
        }
        this._searchValue = value;
        this.searchService.notify(value);
        const queryParams = clone(this.activatedRoute.snapshot.queryParams);
        queryParams['search'] = value == '' ? undefined : value;
        this.router.navigate([], { queryParams, replaceUrl: false });
    }

    constructor(
        public appService: AppService,
        private activatedRoute: ActivatedRoute,
        private api: ApiService,
        private routeMetadata: RouteMetadataService,
        private router: Router,
        private searchService: SearchService,
        private titleService: Title,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams
            ?.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((params) => {
                const term = params['search'] ?? '';
                if (term != this.searchValue) {
                    this.searchValue = term;
                    this.showSearch = term != '';
                }
            });
        this.appService.browserRefresh$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                const route = this.activatedRoute.firstChild;
                // Check for data in the route or nested routes up to one child
                const data =
                    route?.routeConfig?.data ??
                    route?.firstChild?.routeConfig?.data ??
                    {};
                const path = this.routeMetadata.getCurrentPath();
                this.title = this.routeMetadata.getTitle(path);
                this.icon = this.routeMetadata.getIcon(path);
                this.titleService.setTitle(this.translate.instant(this.title));
                this.canSearch = true;
                if (data['canSearch'] != undefined) {
                    this.canSearch = !!data['canSearch'];
                }
                this.showSaveIcon = !!data['showSaveIcon'];
            });

        this.api.announcements
            .listen({ unread: true, isActive: true })
            .subscribe((unread) => {
                this.hasUnreadAnnouncements = unread.length > 0;
            });
    }

    openMenu(hover = false) {
        const now = +new Date();
        if (hover && now < this.allowHoverMenuTriggerTimestamp) {
            // This prevents the menu from immediately opening again when the
            // mouse is over the menu button.
            return;
        }
        this.showMenu = true;
    }

    closeMenu() {
        this.allowHoverMenuTriggerTimestamp = +new Date() + 100;
        this.showMenu = false;
    }

    openSearch() {
        this.showSearch = true;
        this.searchElement.nativeElement.focus();
    }

    clearSearch() {
        this.showSearch = false;
        this.searchValue = '';
    }

    inputBlurred() {
        if (this.searchValue == '') {
            this.showSearch = false;
        }
    }
}
