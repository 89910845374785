<div
    id="nav-bar"
    class="text-on-primary-500 relative z-[999] flex h-11 flex-row items-center justify-between bg-primary-500 p-1 dark:bg-base-300 dark:text-base-content"
>
    @if (!showMenu) {
    <button
        class="flex h-9 items-center gap-1 truncate"
        (click)="openMenu()"
        (mouseenter)="openMenu(true)"
    >
        <div class="indicator m-1.5 min-w-[24px]">
            <mat-icon [svgIcon]="icon" />
            @if (hasUnreadAnnouncements) {
            <span class="badge indicator-item badge-accent badge-xs"></span>
            }
        </div>
        <span class="truncate font-title text-xl font-medium">
            {{ title | translate }}
        </span>
    </button>
    <div *ngIf="showSaveIcon" class="h-9 rounded-lg p-1.5">
        <mat-icon
            [svgIcon]="
                (appService.isSaved$ | async)
                    ? 'cloud-check-outline'
                    : 'autorenew'
            "
            class="text-on-primary-500 dark:text-white"
            [ngClass]="{
                'animate-spin': (appService.isSaved$ | async) === false
            }"
            matTooltip="{{
                ((appService.isSaved$ | async)
                    ? 'changes.saved'
                    : 'changes.saving'
                ) | translate
            }}"
        />
    </div>
    @if(canSearch) {
    <div class="flex flex-row items-center">
        <button
            *ngIf="!showSearch"
            id="search-button"
            class="h-9 rounded-lg p-1.5 hover:bg-white/20"
            (click)="openSearch()"
        >
            <mat-icon svgIcon="magnify" />
        </button>
        <input
            #searchInput
            [ngClass]="inputClasses"
            placeholder="{{ 'search' | translate }}"
            [(ngModel)]="searchValue"
            (blur)="inputBlurred()"
        />
        <button
            *ngIf="showSearch"
            id="close-button"
            class="h-9 rounded-lg p-1.5 hover:bg-white/20"
            (click)="clearSearch()"
        >
            <mat-icon svgIcon="close" />
        </button>
    </div>
    } }
</div>
<app-nav-menu [class.invisible]="!showMenu" (hide)="closeMenu()" />
