import { RouterIcon } from 'src/app/root/model/root.model';
import { CustomerRole } from 'src/app/services/constants';
import { hasRole } from 'src/app/shared/common';

export type TopButton = {
    id: string;
    icon: string;
    text?: string;
    notificationCount?: number;
};

export type NavSection = {
    id: string;
    title: string;
    items: NavItem[];
};

export type NavItem = {
    icon: string;
    name: string;
    description: string;
    path: string;
    role: CustomerRole;
};

// Get the sections that the current user has access to.
// Don't include items that the current user does not have access to.
export const getAccessibleSections = () => {
    return allSections
        .map((section) => ({
            ...section,
            items: section.items.filter((item) => hasRole(item.role)),
        }))
        .filter((section) => section.items.length > 0);
};

// TODO: some of the descriptions are descriptions, some are actions.
//       BE CONSISTENT
const allSections: NavSection[] = [
    {
        id: 'features',
        title: 'nav.title.features',
        items: [
            {
                icon: RouterIcon.DASHBOARDS,
                name: 'dashboard.plural',
                description: 'nav.description.dashboards',
                path: '/dashboards',
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.DATA_FILES,
                name: 'title.data_files',
                description: 'nav.description.data_files',
                path: '/data-files-old', // TODO: use /data-files when it is ready for production
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.REPORTS,
                name: 'report.plural',
                description: 'nav.description.reports',
                path: '/reports',
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.STATISTICS,
                name: 'statistic.plural',
                description: 'nav.description.statistics',
                path: '/statistics',
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.ALERTS,
                name: 'alert.plural',
                description: 'nav.description.alerts',
                path: '/alerts',
                role: CustomerRole.OEM,
            },
            {
                icon: RouterIcon.GEO_FENCING,
                name: 'geo_fence.plural',
                description: 'nav.description.geo_fences',
                path: '/geo-fences',
                role: CustomerRole.Dealer,
            },
        ],
    },
    {
        id: 'customization',
        title: 'nav.title.customization',
        items: [
            {
                icon: RouterIcon.MODELS,
                name: 'model.plural',
                description: 'nav.description.models',
                path: '/models',
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.MODULES,
                name: 'module.plural',
                description: 'nav.description.modules',
                path: '/modules',
                role: CustomerRole.OEM,
            },
            {
                icon: RouterIcon.CUSTOM_WIDGETS,
                name: 'custom_widget.plural',
                description: 'nav.description.custom_widgets',
                path: '/widgets',
                role: CustomerRole.OEM,
            },
        ],
    },
    {
        id: 'management',
        title: 'nav.title.management',
        items: [
            {
                icon: RouterIcon.DEVICES,
                name: 'device.plural',
                description: 'nav.description.devices',
                path: '/devices',
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.USERS,
                name: 'user.plural',
                description: 'nav.description.users',
                path: '/users',
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.ORGANIZATIONS,
                name: 'organization.plural',
                description: 'nav.description.organizations',
                path: '/organizations',
                role: CustomerRole.Dealer,
            },
        ],
    },
    {
        id: 'extras',
        title: 'nav.title.extras',
        items: [
            {
                icon: RouterIcon.PAGE_VIEWS,
                name: 'utilities.page_views',
                description: 'nav.description.page_views',
                path: '/page-views',
                role: CustomerRole.Super,
            },
            {
                icon: RouterIcon.DEVICES_CONNECTION_LOG,
                name: 'device.connections',
                description: 'nav.description.connections',
                path: '/device-connections',
                role: CustomerRole.Super,
            },
            {
                icon: RouterIcon.DASHBOARD_OVERVIEW,
                name: 'utilities.dashboard_overview',
                description: 'nav.description.dashboard_overview',
                path: '/dashboards/overview',
                role: CustomerRole.Super,
            },
            {
                icon: RouterIcon.SIMULATOR,
                name: 'utilities.simulator',
                description: 'nav.description.simulator',
                path: '/device-simulators',
                role: CustomerRole.Super,
            },
        ],
    },
    {
        id: 'help',
        title: 'nav.title.help',
        items: [
            // TODO: point to the new documentation
            {
                icon: 'human-male-board',
                name: 'docs',
                description: 'nav.description.docs',
                path: '/help/user-docs',
                role: CustomerRole.Client,
            },
            {
                icon: 'wizard-hat',
                name: 'docs.api',
                description: 'nav.description.api_docs',
                path: '/help/api-docs',
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.ABOUT,
                name: 'about',
                description: 'nav.description.about',
                path: '/about',
                role: CustomerRole.Client,
            },
            {
                icon: 'bullhorn',
                name: 'announcement.plural',
                description: 'nav.description.announcements',
                path: '/announcements',
                role: CustomerRole.Client,
            },
            {
                icon: RouterIcon.ROADMAP,
                name: 'roadmap',
                description: 'nav.description.roadmap',
                path: '/about/roadmap',
                role: CustomerRole.Client,
            },
        ],
    },
];
