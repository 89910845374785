import { NgModule } from '@angular/core';
import { RoadmapComponent } from './roadmap.component';
import { CommonModule } from '../common/common.module';

@NgModule({
    imports: [CommonModule],
    declarations: [
        RoadmapComponent,
    ],
    exports: [
        RoadmapComponent,
    ],
})
export class RoadmapModule {}