import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getUserId } from 'src/app/shared/common';
import { customerRoutes } from '../constants';
import { SettingsService } from '../user/settings.service';
import { VersionService } from '../version/version.service';
import { ApiService } from '../api/api.service';
import { PageView } from 'src/app/shared/models/page-view';

@Injectable({ providedIn: 'root' })
export class PageViewService {
    private _pageView$ = new Subject();
    pageViewSubscription;

    constructor(
        public overlayContainer: OverlayContainer,
        public settings: SettingsService,
        public versionService: VersionService,
        public dialogue: MatDialog,
        private api: ApiService
    ) {
        combineLatest([this._pageView$, this.settings.loaded$])
            .pipe(filter(([_, values]) => Object.keys(values).length > 0))
            .subscribe(async ([pageView, _]) => {
                // Send page view to backend here
                await this._recordPageView(pageView);
            });
    }

    addPageView(path) {
        // Add page view to _pageView$ Subject
        this._pageView$.next(path);
    }

    async _recordPageView(path) {
        const url = new URL(path, window.location.origin);
        const urlArray = url.pathname.split('/');
        let feature = urlArray[1];
        if (customerRoutes.includes(feature)) {
            feature = 'customers';
        }
        const screenWidth = window.innerWidth;
        const frontendVersion = this.versionService.projectVersion('frontend');

        const pageView: PageView = {
            path,
            feature,
            screenWidth,
            frontendVersion,
        };

        const itemId = Number(urlArray[2]);
        if (itemId) {
            pageView.itemId = itemId;
        }

        const userId = getUserId();
        const trackUserId = this.settings.get('account.identified-tracking');
        if (trackUserId && userId != null) {
            pageView.userId = userId;
        }

        this.api.pageViews.record(pageView);
    }
}
