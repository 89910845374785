import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HistoryItem } from 'src/app/shared/models/version';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class VersionService {
    projects = ['frontend', 'backend'];
    projectNames = {};
    history = {};

    get versionString(): string {
        return this.projects
            .map(
                (project) =>
                    this.projectName(project) +
                    ': ' +
                    (this.projectVersion(project) ?? '?.?.?'),
            )
            .join(' | ');
    }

    constructor(
        private translate: TranslateService,
        private api: ApiService,
        private http: HttpClient,
    ) {
        this.projects.forEach((project) => {
            this.translate.get('about.' + project).subscribe((name: string) => {
                this.projectNames[project] = name;
            });
        });
    }

    // Loads version history into history variable for use in components.
    // Usage in service constructor results in empty backend history.
    async loadHistory() {
        const name = environment.useBetaHistory ? 'beta' : 'production';
        this.http
            .get(`/assets/sz.${name}.json?t=${+new Date()}`)
            .subscribe((data) => {
                this.history['frontend'] = this.convertHistory(
                    data as HistoryItem[],
                );
            });
        const backendVersionHistory = await this.api.versions.history();
        this.history['backend'] = this.convertHistory(backendVersionHistory);
    }

    convertHistory(history: HistoryItem[]) {
        return history.map((item) => ({
            version: item['version'],
            date: new Date(item['date'] * 1000),
            notes: item['notes'] ?? [],
        }));
    }

    projectName(project: string): string {
        return this.projectNames[project] ?? '';
    }

    projectVersion(project: string): string {
        if (
            this.history[project] == null ||
            this.history[project].length == 0
        ) {
            return null;
        }
        return this.history[project][0].version;
    }
}
