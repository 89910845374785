import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavSection, TopButton, getAccessibleSections } from './data';

@Component({ selector: 'app-nav-menu', templateUrl: 'nav-menu.component.html' })
export class NavMenuComponent implements OnInit {
    @Output() hide = new EventEmitter();

    topButtons: TopButton[] = [
        { id: 'close', icon: 'close' },
        { id: 'home', icon: 'home', text: 'common.home' },
        // TODO: re-enable when the documentation is ready
        // { id: 'help', icon: 'help-circle', text: 'Explain This' },
        { id: 'settings', icon: 'cog', text: 'setting.plural' },
        { id: 'signout', icon: 'logout', text: 'auth.sign_out' },
    ];
    sections: NavSection[] = [];
    announcementCount = 0;

    constructor(
        private router: Router,
        private auth: AuthService,
        private api: ApiService,
    ) {}

    ngOnInit() {
        this.sections = getAccessibleSections();

        this.api.announcements
            .listen({ unread: true, isActive: true })
            .subscribe((unread) => {
                const homeButton = this.topButtons.find(
                    ({ id }) => id === 'home',
                );
                homeButton.notificationCount =
                    unread.length > 0 ? unread.length : undefined;
            });
    }

    onTopButtonClicked(id: string) {
        switch (id) {
            case 'close':
                this.hide.emit();
                break;
            case 'home':
                this.router.navigate(['/home']);
                break;
            case 'help': {
                // TODO: go to the documentation page for the current page
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const path = this.router.url;
                break;
            }
            case 'settings':
                this.router.navigate(['/settings']);
                break;
            case 'signout':
                this.auth.signOut();
                break;
        }
        this.hide.emit();
    }
}
