import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonModule } from '../common/common.module';
import { AppMaterialModule } from '../services/app-material/app-material.module';
import { AlertEmailDialog } from './alert-email-dialog/alert-email-dialog.component';
import { AlertTextDialog } from './alert-text-dialog/alert-text-dialog.component';
import { ApplyModelDialog } from './apply-model-dialog/apply-model-dialog.component';
import { ConfirmDeleteDialog } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog.component';
import { CreateCustomerDialog } from './create-customer-dialog/create-customer-dialog.component';
import { CreateDashboardDialog } from './create-dashboard-dialog/create-dashboard-dialog.component';
import { CreateDeviceDialog } from './create-device-dialog/create-device-dialog.component';
import { CreateModelDialog } from './create-model-dialog/create-model-dialog.component';
import { DateRangeDialog } from './date-range-dialog/date-range-dialog.component';
import { DeviceDashboardDialog } from './device-dashboard-dialog/device-dashboard-dialog.component';
import { DeviceFilterDialog } from './device-filter-dialog/device-filter-dialog.component';
import { EditModuleDialog } from './edit-module-dialog/edit-module-dialog.component';
import { EditMqttCommandDialog } from './edit-mqtt-command-dialog/edit-mqtt-command-dialog.component';
import { FeedbackDialog } from './feedback/feedback.component';
import { IdentifiedTrackingDialog } from './identified-tracking-dialog/identified-tracking-dialog.component';
import { ImportJsonDialog } from './import-json-dialog/import-json-dialog.component';
import { NewPasswordDialog } from './new-password-dialog/new-password-dialog.component';
import { SelectDevicesDialog } from './select-devices-dialog/select-devices-dialog.component';
import { SelectDialog } from './select-dialog/select-dialog.component';
import { SelectOrganizationDialog } from './select-organization-dialog/select-organization-dialog.component';
import { SelectUserDialog } from './select-user-dialog/select-user-dialog.component';
import { SignalSelectionDialog } from './signal-selection-dialog/signal-selection-dialog.component';
import { SimpleInputDialog } from './simple-input-dialog/simple-input-dialog.component';
import { UpdateCanDatabaseDialog } from './update-can-database-dialog/update-can-database-dialog.component';
import { UploadCustomerFileDialog } from './upload-customer-file-dialog/upload-customer-file-dialog.component';
import { UploadDataFileDialog } from './upload-data-file-dialog/upload-data-file-dialog.component';
import { FrequencyDistributionDialog } from './frequency-distribution-dialog/frequency-distribution-dialog.component';
import { TimeDifferenceComponent } from './time-difference-dialog/time-difference-dialog.component';

@NgModule({
    declarations: [
        AlertEmailDialog,
        AlertTextDialog,
        ApplyModelDialog,
        ConfirmDeleteDialog,
        ConfirmDialog,
        CreateCustomerDialog,
        CreateDashboardDialog,
        CreateDeviceDialog,
        CreateModelDialog,
        DateRangeDialog,
        DeviceDashboardDialog,
        DeviceFilterDialog,
        EditModuleDialog,
        EditMqttCommandDialog,
        FeedbackDialog,
        IdentifiedTrackingDialog,
        ImportJsonDialog,
        NewPasswordDialog,
        SelectOrganizationDialog,
        SelectDevicesDialog,
        SelectDialog,
        SelectUserDialog,
        SignalSelectionDialog,
        SimpleInputDialog,
        UpdateCanDatabaseDialog,
        UploadCustomerFileDialog,
        UploadDataFileDialog,
        FrequencyDistributionDialog,
        TimeDifferenceComponent,
    ],
    imports: [
        AppMaterialModule,
        RouterModule,
        ScrollingModule,
        NgxMatSelectSearchModule,
        CommonModule,
    ],
    exports: [
        AlertEmailDialog,
        AlertTextDialog,
        ApplyModelDialog,
        ConfirmDeleteDialog,
        ConfirmDialog,
        CreateCustomerDialog,
        CreateDashboardDialog,
        CreateDeviceDialog,
        CreateModelDialog,
        DateRangeDialog,
        DeviceDashboardDialog,
        DeviceFilterDialog,
        EditModuleDialog,
        EditMqttCommandDialog,
        FeedbackDialog,
        IdentifiedTrackingDialog,
        ImportJsonDialog,
        NewPasswordDialog,
        SelectOrganizationDialog,
        SelectDevicesDialog,
        SelectDialog,
        SelectUserDialog,
        SignalSelectionDialog,
        SimpleInputDialog,
        UpdateCanDatabaseDialog,
        UploadCustomerFileDialog,
        UploadDataFileDialog,
        FrequencyDistributionDialog,
        TimeDifferenceComponent,
    ],
})
export class DialogModule {}
