import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MarkdownService } from 'ngx-markdown';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit {
    markdown: string;
    showScrollToTopButton: boolean;
    topPosToStartShowing = 100;
    selectedDoc: string;
    document: string;
    apiDocsUrl: SafeResourceUrl;

    constructor(
        private markdownService: MarkdownService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        // Initialize URL for api-docs
        this.apiDocsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            environment.apiUrl + '/api-docs'
        );
        this.activatedRoute.params.subscribe((p) => {
            this.document = p['doc'] ?? 'user-docs';
            this.show(this.document);
        });
        this.initMarkdown();
    }

    checkScroll() {
        const el = document.getElementById('mark');
        this.showScrollToTopButton = el.scrollTop >= this.topPosToStartShowing;
    }

    scrollToTop() {
        const el = document.getElementById('mark');
        el.scrollTo({ top: 0, behavior: 'smooth' });
    }

    private initMarkdown() {
        this.markdownService.renderer.heading = (
            text: string,
            level: number
        ) => {
            const escapedText = text.toLowerCase().replace(/[^\w]+/g, '-');
            return (
                '<h' +
                level +
                '>' +
                '<a id="' +
                escapedText +
                '">' +
                '<span class="header-link"></span>' +
                '</a>' +
                text +
                '</h' +
                level +
                '>'
            );
        };

        this.markdownService.renderer.link = (href, title, text) => {
            return (
                `<a href="help` +
                '/' +
                this.document +
                href.toLowerCase() +
                `">` +
                text +
                `</a>`
            );
        };
    }

    show(doc: string) {
        this.selectedDoc = doc;

        let sub_path =
            this.translate.currentLang || this.translate.getDefaultLang();
        if (sub_path && sub_path !== 'en') {
            sub_path = sub_path + '/';
        } else {
            sub_path = '';
        }

        switch (doc) {
            case 'api-docs':
                this.markdown = '../assets/docs/' + sub_path + 'apidoc.md';
                break;
            case 'license':
                this.markdown = '../assets/docs/' + sub_path + 'LICENSE.md';
                break;
            case 'terms':
                this.markdown = '../assets/docs/' + sub_path + 'terms.md';
                break;
            case 'user-docs':
            default:
                this.markdown = '../assets/docs/' + sub_path + 'userdoc.md';
                break;
        }
    }
}
