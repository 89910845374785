<div class="mat-typography flex flex-col items-center">
    <mat-card appearance="outlined" class="m-4">
        <div class="flex w-80 flex-col items-center gap-6 p-3 text-center">
            <div class="font-bold">
                {{ 'reset_password_success.title' | translate }}
            </div>
            <div>
                {{ 'reset_password_success.content' | translate }}
            </div>
            <a mat-flat-button class="!bg-orange-500 !text-white" href="/home">
                {{ 'bad_route.go_home' | translate }}
            </a>
        </div>
    </mat-card>
</div>
