<ng-container *ngIf="document !== 'api-docs'; else apiDocs">
    <div class="fixed bottom-4 right-7 z-50">
        <button
            mat-raised-button
            class="mat-primary !h-14 !w-14 !min-w-0 !rounded-2xl !p-0 !shadow-xl !transition-transform"
            [class.scale-100]="showScrollToTopButton"
            [class.scale-0]="!showScrollToTopButton"
            (click)="scrollToTop()"
        >
            <mat-icon
                svgIcon="chevron-up"
                class="text-on-primary-500 !m-0"
            ></mat-icon>
        </button>
    </div>
    <div
        id="mark"
        class="scroll-content-no-tool-bar p-3"
        (scroll)="checkScroll()"
    >
        <markdown
            [src]="markdown"
            class="prose prose-neutral select-text dark:prose-invert"
        ></markdown>
    </div>
</ng-container>
<ng-template #apiDocs>
    <div class="fixed top-[44px] inset-0 flex">
        <iframe [src]="apiDocsUrl" class="flex-1 border-none z-50"></iframe>
    </div>
</ng-template>
