import { formatCurrency, getLocaleId } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import byteSize from 'byte-size';
import 'datatables.net';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api/api.service';
import { ensureLoaded } from '../services/api/cached-api';
import { DataTableLanguageService } from '../services/data-table-language/data-table-language.service';
import { DateTimeService } from '../services/date-time.service';
import { BillingData } from '../shared/models/billing';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
    id: number;
    isHidden = true;
    isLoading = true;
    billing: BillingData;
    customerName: string;
    logoUrl: string;
    startDateString: string;
    endDateString: string;
    totalDataUsageString = '';
    billTableOptions;
    deviceTableOptions;

    get localeId() {
        return getLocaleId(this.locale);
    }

    get logo() {
        return environment.apiUrl + '/api/v2/customers/' + this.id + '/logo';
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private api: ApiService,
        @Inject(LOCALE_ID) public locale: string,
        private dateTimeService: DateTimeService,
        private translate: TranslateService,
        private dataTableLanguageService: DataTableLanguageService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        ensureLoaded([this.api.organizations]);
        this.activatedRoute.params.subscribe(async (params) => {
            if (params['id'] != undefined) {
                this.id = params['id'];
                const customer = this.api.organizations.get(this.id);
                if (customer === null) {
                    this.router.navigate(['not-found']);
                } else {
                    this.customerName = customer['name'];
                    this.logoUrl = customer['logo'];
                    this.billing = await this.api.billing.getCurrent(this.id);
                    this.loadTables();
                }
            }
        });
    }

    async loadTables() {
        const deviceTranslation = await this.translate
            .get(['device', 'device.iccid', 'device.data_usage'])
            .toPromise();
        const billTranslation = await this.translate
            .get(['bill.item', 'bill.quantity', 'bill.price', 'bill.amount'])
            .toPromise();
        this.deviceTableOptions = {
            destroy: true,
            responsive: { details: false },
            columns: [
                {
                    data: 'identifier',
                    title: deviceTranslation['device'],
                },
                {
                    data: 'iccid',
                    title: deviceTranslation['device.iccid'],
                    orderable: false,
                    width: '30%',
                },
                {
                    data: 'dataUsage',
                    title: deviceTranslation['device.data_usage'],
                    width: '20%',
                    className: 'text-end',
                    render: function (data, type) {
                        if (type == 'display') {
                            const size = byteSize(data, { precision: 2 });
                            return size.value + ' ' + size.unit;
                        }
                        return data;
                    },
                },
            ],
            order: [[2, 'desc']],
            ajax: (data, callback) => {
                this.isLoading = false;
                callback({ data: this.billing.devices });
            },
            deferRender: true,
            info: false,
            paging: false,
            scrollY: 'calc(100vh - 520px)',
            scrollCollapse: true,
            scroller: true,
            searching: false,
            language: this.dataTableLanguageService.getLanguageData(),
        };

        const localeId = this.localeId;
        this.billTableOptions = {
            destroy: true,
            responsive: { details: false },
            columns: [
                {
                    data: 'item',
                    title: billTranslation['bill.item'],
                    orderable: false,
                },
                {
                    data: 'qty',
                    title: billTranslation['bill.quantity'],
                    orderable: false,
                    width: '5%',
                    className: 'align-center',
                },
                {
                    data: 'price',
                    title: billTranslation['bill.price'],
                    orderable: false,
                    width: '10%',
                    className: 'align-right',
                    render: function (data, type) {
                        if (type == 'display') {
                            if (data == null) {
                                return '';
                            }
                            return formatCurrency(data, localeId, '$', 'USD');
                        }
                        return data;
                    },
                },
                {
                    data: 'amount',
                    title: billTranslation['bill.amount'],
                    orderable: false,
                    width: '10%',
                    className: 'align-right',
                    render: function (data, type) {
                        if (type == 'display') {
                            return formatCurrency(data, localeId, '$', 'USD');
                        }
                        return data;
                    },
                },
            ],
            order: [],
            ajax: (data, callback) => {
                this.startDateString = this.dateTimeService.dateString(
                    this.billing.startDate,
                );
                this.endDateString = this.dateTimeService.dateString(
                    this.billing.endDate,
                );
                const numPools = this.billing.numPools;
                const poolRate = this.billing.poolRate;
                const poolSize = byteSize(this.billing.poolSize, {
                    precision: 0,
                });
                const poolSizeString = poolSize.value + poolSize.unit;
                const storageCost = this.billing.storageCost;
                const numDevices = this.billing.numDevices;
                const deviceRate = this.billing.deviceRate;
                const totalCost = this.billing.totalCost;
                this.translate
                    .get([
                        'bill.pool',
                        'bill.storage_fee',
                        'bill.device_fee',
                        'bill.total',
                    ])
                    .subscribe((translated) => {
                        const data = [
                            {
                                item:
                                    poolSizeString +
                                    ' ' +
                                    translated['bill.pool'],
                                qty: numPools,
                                price: poolRate,
                                amount: numPools * poolRate,
                            },
                            {
                                item: translated['bill.storage_fee'],
                                qty: 1,
                                price: storageCost,
                                amount: storageCost,
                            },
                            {
                                item: translated['bill.device_fee'],
                                qty: numDevices,
                                price: deviceRate,
                                amount: numDevices * deviceRate,
                            },
                            {
                                item: translated['bill.total'],
                                qty: null,
                                price: null,
                                amount: totalCost,
                            },
                        ];
                        const totalDataUsage = byteSize(
                            this.billing.totalDataUsage,
                            { precision: 2 },
                        );
                        this.totalDataUsageString =
                            totalDataUsage.value + ' ' + totalDataUsage.unit;
                        return callback({ data });
                    });
            },
            deferRender: true,
            info: false,
            paging: false,
            scrollY: '144px',
            scrollCollapse: true,
            scroller: true,
            searching: false,
            language: this.dataTableLanguageService.getLanguageData(),
        };

        this.isHidden = false;
    }
}
