import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewPasswordDialog } from '../dialogs/new-password-dialog/new-password-dialog.component';
import { DialogService } from '../services/dialog/dialog.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    constructor(private dialog: DialogService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.params.subscribe(async (params) => {
            await this.dialog.open(NewPasswordDialog, {
                data: {
                    dialogType: 'reset',
                    token: params['token'],
                },
                disableClose: true,
            });
        });
    }
}
