import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { mergeObjectsRecursively } from '../shared/common';

export type Resource = { prefix: string; suffix: string };

export class MultiTranslateHttpLoader implements TranslateLoader {
    resources: Resource[];

    constructor(
        private readonly http: HttpClient,
        { resources }: { resources: Resource[] }
    ) {
        this.resources = resources;
    }

    getTranslation(lang: string): Observable<Record<string, unknown>> {
        const resources: Resource[] = [...this.resources];

        return forkJoin(
            resources.map((resource: Resource) => {
                return this.http.get<Record<string, unknown>>(
                    `${resource.prefix}${lang}${resource.suffix}`
                );
            })
        ).pipe(
            map((response: Record<string, unknown>[]) =>
                mergeObjectsRecursively(response)
            )
        );
    }
}
