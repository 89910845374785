<nav
    class="absolute flex h-[44px] w-full flex-row items-center bg-gray-500 uppercase text-white"
>
    <div
        class="pointer-events-none relative flex h-full w-[20rem] flex-row items-center bg-orange-600 py-1 px-2 text-xl"
    >
        <img src="assets/img/favicon.ico" width="36px" alt="Spoke Zone logo" />
        <span class="font-bold">Spoke&nbsp;&bull;</span><span>&nbsp;Zone</span>
    </div>
</nav>
