import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ActivatedRoute,
    DefaultUrlSerializer,
    Router,
    UrlTree,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { AuthService } from '../services/auth/auth.service';
import { SettingsService } from '../services/user/settings.service';
import { CustomValidator } from './../shared/custom.validators';
import { SnackBarService } from '../services/snack-bar/snack-bar.service';
import { ApiService } from '../services/api/api.service';

@Component({
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    showForgotPasswordDialog = false;

    form = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            CustomValidator.email,
        ]),
        password: new FormControl('', [Validators.required]),
        rememberMe: new FormControl(true),
    });

    get email() {
        return this.form.get('email');
    }

    get password() {
        return this.form.get('password');
    }

    get emailError() {
        if (this.email.errors?.required) {
            return 'form.email_required';
        } else if (this.email.errors?.customEmailSpacesDavid) {
            return 'Remove the spaces, David!';
        } else if (this.email.errors?.customEmailSpaces) {
            return 'form.email_not_valid_spaces';
        }
        return 'form.email_not_valid';
    }

    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private materialCssVarsService: MaterialCssVarsService,
        private settings: SettingsService,
        private snackBar: SnackBarService,
        private api: ApiService
    ) {}

    async ngOnInit() {
        await this.playVideo();
        this.settings.clear();
        this.materialCssVarsService.setDarkTheme(false);
        localStorage.clear();
    }

    async playVideo() {
        const video = document.getElementById(
            'background-video'
        ) as HTMLVideoElement;
        // For some reason we have to specify this here, instead of in the HTML
        video.muted = true;
        await video.play();
    }

    async login() {
        try {
            await this.auth
                .login(
                    this.email.value,
                    this.password.value,
                    this.form.get('rememberMe').value
                )
                .toPromise();
            const goTo = this.route.snapshot.queryParams['goTo'] ?? '';
            const urlSerializer = new DefaultUrlSerializer();
            const urlTree: UrlTree = urlSerializer.parse(goTo);
            this.router.navigateByUrl(urlTree);
        } catch (error) {
            if (error.error.message) {
                alert(error.error.message);
            } else {
                const title = await this.translate
                    .get('login.invalid_credentials')
                    .toPromise();
                alert(title);
            }
        }
    }

    submit() {
        this.email.markAsTouched();
        this.password.markAsTouched();
        if (this.form.valid) this.login();
    }

    async sendResetEmail() {
        const result = await this.api.users.resetPassword(
            this.form.get('email').value
        );
        if (result) {
            this.snackBar.open('forgot_password.email_sent');
            this.showForgotPasswordDialog = false;
        }
    }
}
