import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { DateTimeService } from '../services/date-time.service';
import { VersionService } from '../services/version/version.service';
import { SnackBarService } from '../services/snack-bar/snack-bar.service';
import { FeedbackDialog } from '../dialogs/feedback/feedback.component';
import { DialogService } from '../services/dialog/dialog.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
})
export class AboutComponent implements OnInit {
    constructor(
        public versionService: VersionService,
        private clipboard: ClipboardService,
        private dateTime: DateTimeService,
        private snackBar: SnackBarService,
        private dialog: DialogService
    ) {}

    ngOnInit(): void {
        this.versionService.loadHistory();
    }

    async feedbackDialog() {
        await this.dialog.open(FeedbackDialog, {
            data: {
                title: 'help.question.title',
                placeholder: 'help.question.description',
                successMessage: 'help.question.submitted',
            },
        });
    }

    async copy() {
        const frontendVersion =
            this.versionService.projectVersion('frontend') ?? '?.?.?';
        const backendVersion =
            this.versionService.projectVersion('backend') ?? '?.?.?';
        const dateString = new Date().toISOString();
        const copyString = `Frontend: ${frontendVersion} | Backend: ${backendVersion} | Date: ${dateString}`;
        this.clipboard.copy(copyString);
        this.snackBar.open('about.versions_copied');
    }

    formatDate(date: Date): string {
        return date == null ? '' : this.dateTime.dateString(date);
    }
}
