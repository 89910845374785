<div class="scroll-content-no-tool-bar w-full overflow-auto p-4 pb-8">
    <div class="flex flex-col">
        <div
            class="mat-elevation-z6 relative m-4 flex aspect-square h-[200px] justify-center self-center rounded-3xl bg-white dark:bg-gray-600 lg:h-[400px]"
        >
            <img
                class="aspect-square w-full"
                src="assets/img/SZ-logo_black.png"
                alt="Spoke Zone Logo"
            />
        </div>
        <p class="mat-subheading-2 self-center">
            {{ versionService.versionString }}
        </p>
        <div class="flex flex-row items-center justify-center gap-3">
            <button
                mat-flat-button
                color="primary"
                class="center self-center"
                (click)="feedbackDialog()"
            >
                <mat-icon svgIcon="help-circle-outline"></mat-icon>
                <span>{{ 'help.question.title' | translate }}</span>
            </button>
            <button
                mat-stroked-button
                class="center self-center"
                ngxClipboard
                (click)="copy()"
            >
                <mat-icon svgIcon="content-copy"></mat-icon>
                <span>{{ 'about.copy_versions' | translate }}</span>
            </button>
        </div>
    </div>
    <div class="mt-8 flex flex-col gap-x-4 lg:flex-row">
        <div
            class="w-full flex-1"
            *ngFor="let project of versionService.projects"
        >
            <h2>
                {{ versionService.projectName(project) }}
                {{ 'about.release_notes' | translate }}
            </h2>
            <mat-accordion>
                <mat-expansion-panel
                    *ngFor="let item of versionService.history[project]"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ item.version ? 'v' + item.version : '' }}
                        </mat-panel-title>
                        <mat-panel-description>
                            {{ formatDate(item.date) }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <p *ngFor="let note of item.notes">{{ note }}</p>
                        <p *ngIf="item.notes.length === 0">
                            {{ 'about.no_release_notes' | translate }}
                        </p>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
