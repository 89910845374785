import { OverlayContainer } from '@angular/cdk/overlay';
import {
    Component,
    DestroyRef,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { filter, first } from 'rxjs/operators';
import { AppService } from '../app.service';
import { ApiService } from '../services/api/api.service';
import { RouteMetadataService } from '../services/route-metadata/route-metadata.service';
import { SettingsService } from '../services/user/settings.service';
import { VersionService } from '../services/version/version.service';
import { PageViewService } from './../services/page-view/page-view.service';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
})
export class RootComponent implements OnInit, OnDestroy {
    navEventsSubscription;
    firstNavEventSubscription;
    destroyRef = inject(DestroyRef);

    constructor(
        public overlayContainer: OverlayContainer,
        public settings: SettingsService,
        public versionService: VersionService,
        private activatedRoute: ActivatedRoute,
        private api: ApiService,
        private appService: AppService,
        private materialCssVarsService: MaterialCssVarsService,
        private pageViewService: PageViewService,
        private routeMetadata: RouteMetadataService,
        private router: Router,
        private titleService: Title,
        private translate: TranslateService,
    ) {
        this.materialCssVarsService.setPrimaryColor('#ffffff'); // white
        this.materialCssVarsService.setAccentColor('#a3a3a3'); // grey-400
        this.materialCssVarsService.setWarnColor('#ef4444'); // red-500
    }

    async ngOnInit() {
        if (!this.settings.isLoaded) {
            this.settings.init(await this.api.settings.all());
        }
        this.versionService.loadHistory();
        this.appService.browserRefresh$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async () => {
                const path = this.routeMetadata.getCurrentPath();
                const title = this.routeMetadata.getTitle(path);
                this.titleService.setTitle(this.translate.instant(title));
            });

        this.navEventsSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                filter((event: NavigationStart) => {
                    const path = new URL(location.origin + event.url).pathname;
                    // Check if we're navigating to a new page.
                    // We don't want to record a page view when we're navigating
                    // to the existing page.
                    return location.pathname != path;
                }),
            )
            .subscribe((event: NavigationStart) =>
                this.pageViewService.addPageView(event.url),
            );
        // This will only trigger one time when accessing any page through a URL.
        this.firstNavEventSubscription = this.router.events
            .pipe(first())
            .subscribe((event) =>
                this.pageViewService.addPageView(
                    event['url'] ?? event['routerEvent']['url'],
                ),
            );
    }

    ngOnDestroy(): void {
        if (this.navEventsSubscription) {
            this.navEventsSubscription.unsubscribe();
        }
        if (this.firstNavEventSubscription) {
            this.firstNavEventSubscription.unsubscribe();
        }
    }
}
