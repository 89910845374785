<div class="overflow-clip">
    <video
        id="background-video"
        src="https://spoke.zone/videos/SpokeZoneTopVid.mp4"
        type="video/mp4"
        loop
        playsinline
        class="absolute bottom-0 left-0 right-0 top-0 min-h-full object-cover"
    ></video>
    <div
        class="absolute h-screen w-screen bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAH0lEQVQIW2NggAABIFYAYgMgdsAqwIBNgAGbAAM2AQCXWQKBzohaMwAAAABJRU5ErkJggg==)]"
    ></div>
    <div class="mat-typography h-screen">
        <nav
            class="absolute flex h-[44px] w-full flex-row items-center bg-gray-500 uppercase text-white"
        >
            <div
                class="pointer-events-none relative flex h-full w-[20rem] flex-row items-center bg-orange-600 px-2 py-1 text-xl"
            >
                <img
                    src="assets/img/favicon.ico"
                    width="36px"
                    alt="Spoke Zone logo"
                />
                <span class="font-bold">Spoke&nbsp;&bull;</span
                ><span>&nbsp;Zone</span>
            </div>
            <div class="flex grow flex-row justify-end">
                <a
                    class="pr-4 hover:text-orange-600 hover:underline"
                    href="https://spoke.zone"
                    >{{ 'about' | translate }}</a
                >
            </div>
        </nav>
        <form
            [formGroup]="form"
            class="top-[44px] flex h-full flex-col items-center justify-center"
            [class.forgot-password]="showForgotPasswordDialog"
        >
            <mat-card
                class="flex w-[320px] flex-col gap-2 !rounded-2xl p-4 opacity-95"
            >
                <ng-container
                    *ngIf="showForgotPasswordDialog; else loginDialog"
                >
                    <div class="flex flex-col items-center justify-center">
                        <h1>{{ 'forgot_password.title' | translate }}</h1>
                        <div class="pb-3 text-xs">
                            {{ 'forgot_password.desc' | translate }}
                        </div>
                    </div>
                </ng-container>
                <ng-template #loginDialog>
                    <div
                        *ngIf="!showForgotPasswordDialog"
                        class="pointer-events-none flex select-none flex-row justify-center"
                    >
                        <img
                            alt="Spoke Zone logo"
                            class="mx-auto mb-6"
                            src="assets/img/SZ-logo_black.png"
                            width="240px"
                            height="220px"
                        />
                    </div>
                </ng-template>

                <div class="flex w-full flex-col">
                    <div class="mat-subtitle-2">
                        {{ 'login.email' | translate }}
                    </div>
                    <sz-input
                        class="[&_input]:w-full"
                        [formControl]="email"
                        [showError]="email.invalid"
                        [errorMessage]="emailError"
                    />
                </div>

                <ng-container
                    *ngIf="!showForgotPasswordDialog; else forgotPassword"
                >
                    <div class="flex flex-col">
                        <div class="mat-subtitle-2">
                            {{ 'login.password' | translate }}
                        </div>
                        <sz-input
                            class="[&_input]:w-full"
                            [formControl]="password"
                            [showError]="password.invalid"
                            errorMessage="form.password_is_required"
                            type="password"
                        />
                    </div>
                    <div class="flex flex-row justify-between">
                        <div
                            class="flex flex-row items-center justify-center gap-2 pl-1"
                        >
                            <input
                                type="checkbox"
                                formControlName="rememberMe"
                            />
                            <span>{{ 'login.remember_me' | translate }}</span>
                        </div>
                        <a
                            class="font-light text-sky-700 hover:cursor-pointer hover:underline"
                            (click)="showForgotPasswordDialog = true"
                            >{{ 'login.forgot_password' | translate }}</a
                        >
                    </div>
                    <button
                        mat-flat-button
                        class="!bg-orange-500 !text-white"
                        (click)="submit()"
                    >
                        {{ 'login.sign_in' | translate }}
                    </button>
                </ng-container>
                <ng-template #forgotPassword>
                    <sz-dialog-button-bar
                        primaryText="btn.send_email"
                        [primaryDisabled]="email.invalid"
                        (primaryClick)="sendResetEmail()"
                        (secondaryClick)="showForgotPasswordDialog = false"
                    />
                </ng-template>
            </mat-card>
        </form>
    </div>
</div>
