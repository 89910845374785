<sz-tool-bar [chips]="toolBarChips" />
<div class="scroll-content flex flex-col gap-3 sm:flex-row">
    <sz-section-selector
        class="m-2 sm:m-3"
        [sections]="sections"
        [(selectedIndex)]="selectedIndex"
        (selectedIndexChange)="loadTable()"
    />
    <div class="flex w-full justify-center overflow-y-scroll">
        @if (selectedSectionId === 'all') {
            @if (isSingleDayDateRange) {
                <sz-table
                    class="m-3 w-full sm:w-1/2"
                    [options]="individualTableOptions"
                />
            } @else {
                <div
                    class="absolute flex h-full w-full items-center justify-center"
                >
                    <sz-illustration
                        name="visual-data"
                        title="page_views.date_range.error"
                        description="page_views.date_range.error.description"
                    >
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="setTodayDateRange()"
                        >
                            {{ 'page_views.date_range.today' | translate }}
                        </button>
                    </sz-illustration>
                </div>
            }
        } @else {
            <sz-table class="m-3 w-full sm:w-1/2" [options]="tableOptions" />
        }
    </div>
</div>
