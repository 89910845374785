import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { hasRole, isDefined } from './common';

@Injectable({ providedIn: 'root' })
export class RoleGuard {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
        // Check for data in the route or nested routes up to one child
        const data =
            route?.firstChild?.routeConfig?.data ??
            route?.firstChild?.firstChild?.routeConfig?.data;
        if (isDefined(data?.hasRole) && !hasRole(data.hasRole)) {
            this.router.navigate(['/not-auth']);
            return false;
        }
        return true;
    }
}
